import Vue from 'vue'
import Vuex from 'vuex'
import * as api from '@/services/motorcycle-brands'
import utilities from '@/services/utilities'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		motorcycleBrandList: [],
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		async GET_BRANDS({ commit }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo datos...',
				motorcycleBrandList: [],
			})

			return new Promise((resolve, reject) => {
				api
					.getBrands()
					.then((response) => {
						commit('SET_STATE', {
							motorcycleBrandList: _.cloneDeep(response.data),
						})
						resolve(response.data)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Catálogo de marcas',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		motorcycleBrandList: (state) => state.motorcycleBrandList,
	},
}
