import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function createCustomer(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/taller/clients', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getMotorcycles(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/clients/search-motorcycles/${payload.id}`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function createMotorcycles(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/taller/clients/${payload.clientId}/motorcycle-information`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
