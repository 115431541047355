import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function getBrands() {
	return new Promise((resolve, reject) => {
		return apiClient
			.get('/taller/motorcycle-brands')
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
