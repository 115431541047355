import Vue from 'vue'
import Vuex from 'vuex'
import * as api from '@/services/motorcycle-services'
import utilities from '@/services/utilities'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		servicesList: [],
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		async GET({ commit }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo datos...',
				servicesList: [],
			})

			return new Promise((resolve, reject) => {
				api
					.getAll()
					.then((response) => {
						commit('SET_STATE', {
							servicesList: _.cloneDeep(response.data),
						})
						resolve(response.data)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Catálogo de marcas',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async SEARCH({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo datos...',
			})

			return new Promise((resolve, reject) => {
				api
					.search(payload)
					.then((response) => {
						resolve(response.data)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Catálogo de marcas',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async CREATE_PRE_SERVICE_ORDER({ state }, payload) {
			return new Promise((resolve, reject) => {
				api
					.createPreServiceOrder(payload)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: 'Agenda S2R',
							description: utilities.objectValidate(response, 'message', 'Se ha generado el registro de manera correcta'),
						})
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Agenda S2R',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar las ordenes de servicio'),
						})
						reject(error)
					})
			})
		},
		async SEARCH_CUSTOMER({ state }, payload) {
			return new Promise((resolve, reject) => {
				api
					.searchCustomer(payload)
					.then((response) => {
						resolve(response.data[0])
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Agenda S2R',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar las ordenes de servicio'),
						})
						reject(error)
					})
			})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		servicesList: (state) => state.servicesList,
	},
}
