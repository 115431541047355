import Vue from 'vue'
import Router from 'vue-router'
import PublicLayout from '@/layouts/Public'

Vue.use(Router)

const router = new Router({
	base: process.env.BASE_URL,
	// mode: 'history',
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [
		{
			path: '/',
			component: PublicLayout,
			meta: {
				title: 'Cotizador de servicios - S2R',
			},
		},
	],
})

export default router
