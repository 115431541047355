import axios from 'axios'
import store from 'store'

const apiClient = axios.create({
	baseURL: `${process.env.VUE_APP_API_URL}/api`,
	timeout: 1000 * 20,
})

apiClient.interceptors.request.use((request) => {
	const accessToken =
		'eyJhbGciOiJIUzI1NiJ9.eyJpZCI6MSwiZXhwIjoxNjgzNjc1NDg1ODQwLCJlbWFpbCI6ImppZ29tZXp3QGdtYWlsLmNvbSIsInBob25lIjoiNTUxNDI3ODAwNyIsInJvbGUiOiJzdXBlci1hZG1pbiJ9.VtSxHBkLx_QcY7yjQu6GwY6IIxlS_u_PRvxdRh5XbHU'
	if (accessToken) {
		request.headers = {
			...request.headers,
			Authorization: `Bearer ${accessToken}`,
			accesstoken: accessToken,
			'Access-Control-Allow-Origin': true,
		}
	}
	return request
})

export default apiClient
