import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function getAll() {
	return new Promise((resolve, reject) => {
		return apiClient
			.get('/service-administration')
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function search(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/price-tab/search', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function createPreServiceOrder(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/service-orders/pre-order', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function searchCustomer(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/taller/clients/search', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
