import Vue from 'vue'
import Vuex from 'vuex'
import * as api from '@/services/motorcycle-models'
import utilities from '@/services/utilities'
import _ from 'lodash'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		motorcycleModelList: [],
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET_MODELS_BY_BRAND({ commit }, id) {
			commit('SET_STATE', {
				motorcycleModelList: [],
			})

			api
				.getModels(id)
				.then((response) => {
					commit('SET_STATE', {
						motorcycleModelList: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Catálogo de modelos',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
					})
					commit('SET_STATE', {
						motorcycleModelList: [],
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		motorcycleModelList: (state) => state.motorcycleModelList,
	},
}
