import Vue from 'vue'
import Vuex from 'vuex'
import * as api from '@/services/customers'
import utilities from '@/services/utilities'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		actualRecord: {},
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		async CREATE({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando datos...',
			})

			return new Promise((resolve, reject) => {
				api
					.createCustomer(payload)
					.then((response) => {
						resolve(response.data)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Clientes',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async GET_MOTORCYCLES({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando datos...',
			})

			return new Promise((resolve, reject) => {
				api
					.getMotorcycles(payload)
					.then((response) => {
						resolve(response.data)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Clientes',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async CREATE_MOTORCYCLES({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando datos...',
			})

			return new Promise((resolve, reject) => {
				api
					.createMotorcycles(payload)
					.then((response) => {
						resolve(response.data)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Clientes',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		actualRecord: (state) => state.actualRecord,
	},
}
