<template>
	<a-layout>
		<a-layout-content style="padding: 5% 10%;">
			<a-spin tip="Obteniendo Información" size="large" :spinning="spinnerLoader">
				<div class="row">
					<div class="col-md-12 text-center">
						<img src="resources/images/logo.png" width="100" />
						<h3>- Cotizador de Servicios S2R -</h3>
					</div>
				</div>
				<div class="row" style="padding-top: 15px;">
					<div class="col-md-12 text-center">
						<h1 class="text-muted">Datos de tu Motocicleta</h1>
					</div>
					<div class="col-md-4 text-left" style="margin-top: 10px">
						Selecciona la marca de tu moto
						<a-select showSearch option-filter-prop="children" :filter-option="filterOption" @change="onChangeBrand" style="width: 100%" v-model="brandID">
							<a-select-option v-for="brand in motorcycleBrandList" :key="brand.id" :value="brand.id"> {{ brand.name.toUpperCase() }} </a-select-option>
						</a-select>
					</div>
					<div class="col-md-4 text-left" style="margin-top: 10px">
						Selecciona el modelo de tu moto
						<a-select showSearch option-filter-prop="children" :filter-option="filterOption" style="width: 100%" v-model="modelID" @change="cleanServices">
							<a-select-option v-for="brand in motorcycleModelList" :key="brand.id" :value="brand.id"> {{ brand.name.toUpperCase() }} </a-select-option>
						</a-select>
					</div>
					<div class="col-md-4 text-left" style="margin-top: 10px">
						Año de tu moto
						<a-input v-mask="'####'" v-model="year" />
					</div>
				</div>
				<div class="row" style="padding-top: 15px;">
					<div class="col-md-12 text-center">
						<h1 class="text-muted">Servicios a cotizar</h1>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6 offset-md-3 text-left">
						<a-select showSearch option-filter-prop="children" :filter-option="filterOption" style="width: 100%" v-model="serviceID" :disabled="!brandID || !modelID || !year">
							<a-select-option v-for="element in servicesListFiltered" :key="element.id" :value="element.id"> {{ element.nombre.toUpperCase() }} </a-select-option>
						</a-select>
					</div>
					<div class="col-md-6 offset-md-3 text-right">
						<a-button class="btn btn-success" style="margin-top: 20px;" v-if="serviceID != ''" @click="addService">Añadir</a-button>
					</div>
					<div class="col-md-12" style="padding-top: 20px;">
						<table class="table table-bordered table-condensed table-striped m0">
							<tbody>
								<tr v-for="(service, index) in services" :key="index">
									<td class="text-left">
										<div style="line-height: 13px;">{{ service.nombre }}</div>
										<div>
											<div v-if="service.required_time || service.base">
												<small><b>Mano de obra:</b> {{ service.base ? numeral(service.base).format('$0,0.00') : numeral(service.calculated).format('$0,0.00') }}</small>
												<div v-if="service.spareParts" style="margin-top: -10px;">
													<small><b>Insumos:</b> {{ numeral(service.spareParts).format('$0,0.00') }}</small>
												</div>
											</div>
											<div v-else><small>Cotización sujeta a evaluación</small></div>
										</div>
										<div style="color: red; margin-top: -5px;"><small style="cursor: pointer" @click="onDeleteElement(index)">Eliminar</small></div>
									</td>
									<td class="text-right" width="15%">
										<div style="line-height: 12px;">
											<div v-if="service.required_time || service.base" style="margin-top: 25px;">
												{{ numeral(service.total || service.base).format('$0,0.00') }}
											</div>
											<div v-else><small>Cotización sujeta a evaluación</small></div>
										</div>
									</td>
								</tr>
								<tr v-if="services.length">
									<td class="text-right" style="font-size: 40px;">
										Total
									</td>
									<td class="text-right" width="15%" style="font-size: 30px;">
										<div style=" margin-top: 10px;">{{ numeral(getSubtotal).format('$0,0.00') }}</div>
									</td>
								</tr>
							</tbody>
						</table>
						<div class="text-justify" v-if="services.length">
							<b>NOTAS</b>
							<ol>
								<li>La presente cotización tiene una vigencia de 10 días hábiles a partir de la fecha de registro.</li>
								<li>La cotización no incluye servicios o materiales adicionales a los listados en la descripción.</li>
								<li>Los precios de los productos pueden estar sujetos a cambios, siendo este no mayor al 10% hacia arriba o hacia abajo.</li>
							</ol>
						</div>
					</div>
					<div class="col-md-12 text-center" v-if="services.length">
						<a-button class="btn btn-success" style="margin-top: 20px;" icon="calendar" @click="searchCustomer">Agendar una cita</a-button>
					</div>
				</div>
			</a-spin>
			<a-modal :visible="showModal" title="Agendar cita" :closable="false" :footer="false" width="70%">
				<a-spin tip="Procesando...." size="large" :spinning="spinnerLoader">
					<a-form class="mb-4" :form="form" @submit="handleSubmit">
						<div class="row">
							<div class="col-md-4">
								<a-form-item label="Nombre(s)">
									<a-input v-decorator="[
										'client_name',
										{
											rules: [
												{
													required: true,
													message: 'Campo requerido.',
												},
											],
										},
									]" autocomplete="off" class="text-uppercase" />
								</a-form-item>
							</div>
							<div class="col-md-4">
								<a-form-item label="Primer apellido">
									<a-input v-decorator="[
										'client_surname',
										{
											rules: [
												{
													required: true,
													message: 'Campo requerido.',
												},
											],
										},
									]" autocomplete="off" class="text-uppercase" />
								</a-form-item>
							</div>
							<div class="col-md-4">
								<a-form-item label="Segundo apellido">
									<a-input v-decorator="['client_last_surname']" autocomplete="off" class="text-uppercase" />
								</a-form-item>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<a-form-item label="Teléfono principal">
									<a-input v-mask="'##########'" v-decorator="[
										'primary_phone',
										{
											rules: [
												{
													required: true,
													message: 'Campo requerido.',
												},
											],
										},
									]" autocomplete="off" />
								</a-form-item>
							</div>
							<div class="col-md-3">
								<a-form-item label="Email">
									<a-input v-decorator="[
										'email',
										{
											rules: [
												{
													email: true,
													message: 'Ingresa email válido',
												},
											],
										},
									]" autocomplete="off" />
								</a-form-item>
							</div>
							<div class="col-md-3">
								<a-form-item label="Fecha de la cita">
									<a-date-picker v-decorator="[
										'appointmentDate',
										{
											rules: [
												{
													required: true,
													message: 'Campo requerido.',
												},
											],
										},
									]" placeholder="Selecciona fecha" format="DD-MM-YYYY" :disabled-date="disabledDate" style="width: 100%" />
								</a-form-item>
							</div>
							<div class="col-md-3">
								<a-form-item label="Hora de la cita">
									<a-time-picker v-decorator="[
										'appointmentTime',
										{
											rules: [
												{
													required: true,
													message: 'Campo requerido.',
												},
											],
										},
									]" placeholder="Selecciona hora" style="width: 100%" format="HH:mm" :hideDisabledOptions="true" :disabledHours="disabledHours" :minute-step="15" />
								</a-form-item>
							</div>
						</div>
						<div class="row" style="padding-top: 20px;">
							<div class="col-md-12 text-right">
								<a-button key="back" class="btn" @click="onCloseModal"> Cancelar </a-button>
								<a-button key="submit" class="btn btn-success" icon="calendar" htmlType="submit" style="margin-left: 5px;"> Agendar cita </a-button>
							</div>
						</div>
					</a-form>
				</a-spin>
			</a-modal>
		</a-layout-content>
	</a-layout>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import utilities from '@/services/utilities'
import numeral from 'numeral'
import Swal from 'sweetalert2'
import { mask } from 'vue-the-mask'
import moment from 'moment'

export default {
	name: 'PublicLayout',
	directives: {
		mask,
	},
	computed: {
		...mapState(['settings']),
		...mapGetters('motorcycleBrands', ['motorcycleBrandList']),
		...mapGetters('motorcycleModels', ['motorcycleModelList']),
		...mapGetters('motorcycleServices', ['servicesList']),
		getSubtotal() {
			let amount = 0
			if (this.services.length) {
				this.services.map(currentValue => {
					amount += utilities.objectValidate(currentValue, 'total', false) ? utilities.objectValidate(currentValue, 'total', 0) : currentValue.base
				})
			}
			return amount
		},
		servicesListFiltered() {
			let response = []
			this.servicesList.map(element => {
				let serviceIndex = this.services.findIndex(e => e.id == element.id)
				if (serviceIndex == -1) {
					response.push(element)
				}
			})
			return response
		},
	},
	data() {
		return {
			brandID: '',
			modelID: '',
			year: '',
			serviceID: '',
			services: [],
			precioFactor: process.env.VUE_APP_FACTOR,
			spinnerLoader: false,
			form: this.$form.createForm(this),
			showModal: false,
			payloadData: {},
		}
	},
	async mounted() {
		this.spinnerLoader = true
		await this.$store.dispatch('motorcycleBrands/GET_BRANDS')
		await this.$store.dispatch('motorcycleServices/GET')
		this.spinnerLoader = false
	},
	methods: {
		moment,
		numeral,
		range(start, end) {
			const result = [];
			for (let i = start; i < end; i++) {
				result.push(i);
			}
			return result;
		},
		disabledHours() {
			return [1, 2, 3, 4, 5, 6, 7, 8, 18, 19, 20, 21, 22, 23, 24]
		},
		cleanServices() {
			this.services = []
		},
		disabledDate(current) {
			// console.log('current', moment(current).weekday());
			return current && current < moment().endOf('day') || moment(current).weekday() == 0;
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
		async onChangeBrand(brand_id) {
			//
			this.cleanServices()
			this.modelID = ''
			this.spinnerLoader = true
			await this.$store.dispatch('motorcycleModels/GET_MODELS_BY_BRAND', brand_id)
			this.spinnerLoader = false
		},
		async addService() {
			this.spinnerLoader = true
			if (!this.services.includes(this.serviceID)) {
				let info = this.servicesList.find(e => e.id == this.serviceID)

				if (!utilities.objectValidate(info, 'base', false)) {
					let payload = {
						brandId: this.brandID,
						modelId: this.modelID,
						serviceID: this.serviceID,
					}

					await this.$store.dispatch('motorcycleServices/SEARCH', payload).then(response => {
						if (response) {
							info = {
								...info,
								required_time: response.required_time,
								spareParts: response.spareParts,
								calculated: this.precioFactor / 60 * response.required_time,
							}

							info.total = (response.base ? numeral(response.base).value() : numeral(info.calculated).value()) + utilities.objectValidate(response, 'spareParts', 0)
						}
					})
				}

				this.services.push(info)
				this.serviceID = ''
				this.spinnerLoader = false
			}
		},
		onDeleteElement(index) {
			Swal.fire({
				title: 'Atención',
				html: `¿Deseas eliminar el servicio <b>"${this.services[index].nombre}"</b>?`,
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					let newElements = []
					this.services.forEach((e, i) => {
						if (i != index) {
							newElements.push(e)
						}
					})
					setTimeout(() => {
						this.services = _.cloneDeep(newElements)
					}, 10);
				}
			})
		},
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields(async (err, values) => {
				if (!err) {
					// this.spinnerLoader = true

					if (!utilities.objectValidate(this.payloadData, 'clientData.id', false)) {
						let payload = {
							client_name: values.client_name,
							client_surname: values.client_surname,
							client_last_surname: utilities.objectValidate(values, 'client_last_surname', ''),
							primary_phone: values.primary_phone,
							email: utilities.objectValidate(values, 'email', ''),
						}

						await this.$store.dispatch('customers/CREATE', payload).then((newCustomer) => {
							this.payloadData.clientData = newCustomer
						})
					}

					// Buscar moto o registrarla
					this.$store.dispatch('customers/GET_MOTORCYCLES', {
						id: this.payloadData.clientData.id,
						id_marca: this.brandID,
						id_modelo: this.modelID,
					}).then((response) => {
						if (response.length) {
							this.payloadData.motorcycleData = response[0]
						} else {
							let motoPayload = {
								clientId: this.payloadData.clientData.id,
								motorcycle_brand_id: this.brandID,
								motorcycle_model_id: this.modelID,
								year: this.year,
								displacement: '',
								plate: '',
								niv: '',
							}
							this.$store.dispatch('customers/CREATE_MOTORCYCLES', motoPayload).then((responseMotorcycle) => {
								this.payloadData.motorcycleData = responseMotorcycle
							})
						}
					})

					// Añadimos los servicios al objeto de datos
					this.payloadData.serviceData = {
						"services": [],
						"especificos": [],
						"diagnostico": "",
						"quotationAmounts": {
							"mayorMenor": 0,
							"valoracion": 0,
							"diagnostico": 0,
							"especifico": 0,
							"exploradoras": 0,
							"gps": 0,
						},
					}

					this.services.forEach(e => {
						let indexOf = undefined
						switch (e.tag) {
							case 'mayor':
								this.payloadData.serviceData.services.push(e.tag)
								//
								this.payloadData.serviceData.quotationAmounts.mayorMenor += e.calculated
								break;
							case 'menor':
								this.payloadData.serviceData.services.push(e.tag)
								//
								this.payloadData.serviceData.quotationAmounts.mayorMenor += e.calculated
								break;
							case 'valoracion':
								this.payloadData.serviceData.services.push(e.tag)
								//
								this.payloadData.serviceData.quotationAmounts.valoracion += e.base
								break;
							case 'sliders':
								indexOf = this.payloadData.serviceData.services.findIndex(e => e == 'especifico')
								if (indexOf == -1) {
									this.payloadData.serviceData.services.push('especifico')
								}
								//
								this.payloadData.serviceData.especificos.push({ hours: "0", minutes: "30", amount: e.base, value: e.nombre })
								break;
							case 'especifico':
								indexOf = this.payloadData.serviceData.services.findIndex(e => e == 'especifico')
								if (indexOf == -1) {
									this.payloadData.serviceData.services.push('especifico')
								}
								//
								let toHoursAndMinutes = utilities.toHoursAndMinutes(e.required_time)
								this.payloadData.serviceData.especificos.push({ hours: toHoursAndMinutes.hours, minutes: toHoursAndMinutes.minutes, amount: e.total, value: e.nombre })
								this.payloadData.serviceData.quotationAmounts.especifico += e.total
								break;
						}
					})

					this.payloadData = {
						...this.payloadData,
						quotation_amount: this.getSubtotal,
						appointment_origin: "",
						from: "cotizador",
						appointment_date: `${moment(values.appointmentDate).format('YYYY-MM-DD')} ${moment(values.appointmentTime).format('HH:mm')}`,
					}

					Swal.fire({
						title: 'Atención',
						text: '¿Deseas confirmar tu cita a servicio?',
						icon: 'warning',
						showCancelButton: true,
						reverseButtons: true,
						confirmButtonColor: '#41b883',
						confirmButtonText: 'Sí, continuar',
						cancelButtonColor: '#f5222e',
						cancelButtonText: 'No, cancelar',
					}).then(async (result) => {
						if (result.isConfirmed) {
							this.spinnerLoader = true
							await this.$store.dispatch('motorcycleServices/CREATE_PRE_SERVICE_ORDER', this.payloadData)

							this.spinnerLoader = false

							setTimeout(() => {
								this.onCloseModal()
							}, 3000);

							setTimeout(() => {
								document.location.reload()
							}, 1000);
						}
					})
				}
			})
		},
		onCloseModal() {
			this.showModal = false
			this.form.resetFields()
		},
		onShowModal() {
			this.showModal = true
		},
		searchCustomer() {
			Swal.fire({
				title: 'Ingresa tu número celular',
				input: 'text',
				inputLabel: 'Para poder validar tu cuenta o generar el registro',
				inputValue: '',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'Cancelar',
				inputValidator: (value) => {
					if (!value || isNaN(value) || value.length != 10) {
						return 'Ingresa número celular válido y a 10 dígitos'
					}
				},
			}).then((result) => {
				if (result.isConfirmed) {
					// Buscar cliente o registrarlo
					this.$store.dispatch('motorcycleServices/SEARCH_CUSTOMER', {
						searchText: result.value,
					}).then(response => {
						this.showModal = true

						setTimeout(() => {
							if (utilities.objectValidate(response, 'id', false)) {
								this.payloadData.clientData = response

								this.form.setFieldsValue({
									client_name: utilities.objectValidate(response, 'client_name', ''),
									client_surname: utilities.objectValidate(response, 'client_surname'),
									client_last_surname: utilities.objectValidate(response, 'client_last_surname', ''),
									primary_phone: utilities.objectValidate(response, 'primary_phone', ''),
									email: utilities.objectValidate(response, 'email', ''),
								})
							}
							this.form.setFieldsValue({
								appointmentTime: moment().hours(9).minutes(0),
							})
						}, 10);
					})
				}
			})
		},
	},
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>

<style lang="scss">
.steps-content {
	margin-top: 16px;
	border: 1px dashed #e9e9e9;
	border-radius: 6px;
	background-color: #fafafa;
	min-height: 200px;
	text-align: center;
	padding: 30px 0;
}

.steps-action {
	margin-top: 24px;
}

.ant-form-item {
	margin-bottom: 10px !important;
}

.ant-form-item-label {
	padding: 0 !important;

	label {
		margin-top: 15px;
		margin: 0;
	}
}
</style>