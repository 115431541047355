import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import settings from './settings'
import motorcycleBrands from './motorcycle-brands'
import motorcycleModels from './motorcycle-models'
import motorcycleServices from './motorcycle-services'
import customers from './customers'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		user,
		settings,
		motorcycleBrands,
		motorcycleModels,
		motorcycleServices,
		customers,
	},
	state: {},
	mutations: {},
	actions: {},
})
